import Page from './Page';
import React from 'react';
import Stop from './Stop';
import { Box, Caps, Flex } from '../elements';

const Error = () => {
  return (
    <Page title="Error">
      <Flex flexDirection="column" justify="center" width={1}>
        <Box color="BLUE" width={1} m={3}>
          <Caps fontSize={[3, null, 6]} fontWeight="bold" textAlign="center">
            Error
          </Caps>
        </Box>
        <Box width={1 / 2} mx="auto">
          <Stop />
        </Box>
        <Box color="BLUE" width={1} m={3}>
          <Caps fontSize={[2, null, 4]} fontWeight="bold" textAlign="center">
            GMC-IT has been notified
          </Caps>
        </Box>
      </Flex>
    </Page>
  );
};

export default Error;
