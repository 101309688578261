import React from 'react';
import { Box, Image } from '../elements';
import safe from '../assets/safe.png';

const SAFE = () => {
  return (
    <Box>
      <Image src={safe} alt="AISC 2023 Safety Award" />
    </Box>
  );
};

export default SAFE;
