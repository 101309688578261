import React from 'react';
import { Text } from 'rebass';

const Caps = props => (
  <Text
    {...props}
    style={{
      textTransform: 'uppercase',
      letterSpacing: '0.2em'
    }}
  />
);

export { Caps };
