import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '../elements';
import { NAVIGATION } from '../theme/z-Indexes';
import NavigationLink from './NavigationLink';

const NavLinkHover = styled(Box)`
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: #ada9bb;
  }
`;

class NavigationLinkDropdown extends Component {
  state = {
    isOpen: false
  };

  handleMouseEnter = event => {
    event.preventDefault();
    this.setState({
      isOpen: true
    });
  };

  handleMouseLeave = event => {
    event.preventDefault();
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { justify, link } = this.props;
    const { isOpen } = this.state;
    return (
      <Box
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {link && (
          <Flex justify={justify} alignItems="baseline">
            <Box style={{ display: 'inline-block' }}>
              <NavigationLink
                fontSize={[2, null, null, 3]}
                to={link.path}
                text={link.text}
              />
            </Box>
          </Flex>
        )}

        {isOpen && link && (
          <Box
            style={{
              position: 'absolute',
              display: 'block',
              zIndex: NAVIGATION
            }}
            bg="BLUE"
            color="WHITE"
          >
            {link.links &&
              link.links.map((dropdownLink, index) => (
                <NavLinkHover key={index}>
                  <Flex justify="flex-start" px={1}>
                    <NavigationLink
                      fontSize={[2, null, null, 3]}
                      to={dropdownLink.path}
                      text={dropdownLink.text}
                    />
                  </Flex>
                </NavLinkHover>
              ))}
          </Box>
        )}
      </Box>
    );
  }
}

NavigationLinkDropdown.propTypes = {
  justify: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

NavigationLinkDropdown.defaultProps = {
  justify: 'center'
};

export default NavigationLinkDropdown;
