import store from '../../store/initialState';
const initialState = store.project;

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const CHANGE_PROJECTS_FILTERS = 'CHANGE_PROJECTS_FILTERS';

export const actions = {
  changeProjectsFilters: filter => ({ type: CHANGE_PROJECTS_FILTERS, filter }),
  fetchProjectsFailure: error => ({ type: FETCH_PROJECTS_FAILURE, error }),
  fetchProjectsRequest: () => ({ type: FETCH_PROJECTS_REQUEST }),
  fetchProjectsSuccess: projects => ({
    type: FETCH_PROJECTS_SUCCESS,
    projects
  }),
  fetchProjectFailure: error => ({ type: FETCH_PROJECT_FAILURE, error }),
  fetchProjectRequest: number => ({ type: FETCH_PROJECT_REQUEST, number }),
  fetchProjectSuccess: project => ({
    type: FETCH_PROJECT_SUCCESS,
    project
  })
};

export const actionCreators = {
  changeProjectsFilters: filter => {
    return actions.changeProjectsFilters(filter);
  },
  fetchProjects: () => {
    return actions.fetchProjectsRequest();
  },
  fetchProject: number => {
    return actions.fetchProjectRequest(number);
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  let filter;
  let filters;

  switch (action.type) {
    case CHANGE_PROJECTS_FILTERS:
      filters = state.filters;
      filter = filters.find(item => item.name === action.filter);
      filter.isActive = !filter.isActive;
      return { ...state, filters: [...filters] };

    case FETCH_PROJECTS_FAILURE:
      return { ...state, projects: [], error: action.error, isLoading: false };

    case FETCH_PROJECTS_REQUEST:
      return { ...state, error: null, isLoading: true };

    case FETCH_PROJECTS_SUCCESS:
      const portfolio = action.projects;
      const projects = portfolio.portfolios;
      filters = portfolio.structures.map(source => {
        const target = {
          isActive: false,
          name: source.structure,
          count: source.count
        };

        return target;
      });
      return {
        ...state,
        projects: projects,
        filters: filters,
        error: null,
        isLoading: false
      };

    case FETCH_PROJECT_FAILURE:
      return { ...state, project: null, error: action.error, isLoading: false };

    case FETCH_PROJECT_REQUEST:
      return { ...state, number: action.number, error: null, isLoading: true };

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        error: null,
        isLoading: false
      };

    default:
      return state;
  }
};
