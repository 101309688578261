export const ABOUT = {
  path: '/about',
  text: 'About',
  breadcrumbs: [
    {
      path: '/about',
      text: 'About'
    }
  ],
  links: [
    {
      path: '/expertise',
      text: 'Expertise'
    },
    {
      path: '/capabilities',
      text: 'Capabilities'
    },
    {
      path: '/leadership',
      text: 'Leadership'
    },
    {
      path: '/history',
      text: 'History'
    }
  ]
};

export const CAPABILITIES = {
  path: '/capabilities',
  text: 'Capabilities',
  breadcrumbs: [
    {
      path: '/about',
      text: 'About'
    },
    {
      path: '/capabilities',
      text: 'Capabilities'
    }
  ],
  links: []
};

export const JOBOPENINGS = {
  path: '/jobopenings',
  text: 'Careers',
  breadcrumbs: [
    {
      path: '/jobopenings',
      text: 'Careers'
    }
  ],
  links: []
};

export const CONTACT = {
  path: '/contact',
  text: 'Contact',
  breadcrumbs: [
    {
      path: '/contact',
      text: 'Contact'
    }
  ],
  links: []
};

export const EXPERTISE = {
  path: '/expertise',
  text: 'Expertise',
  breadcrumbs: [
    {
      path: '/about',
      text: 'About'
    },
    {
      path: '/expertise',
      text: 'Expertise'
    }
  ],
  links: []
};

export const LEADERSHIP = {
  path: '/leadership',
  text: 'Leadership',
  breadcrumbs: [
    {
      path: '/about',
      text: 'About'
    },
    {
      path: '/leadership',
      text: 'Leadership'
    }
  ],
  links: []
};

export const HISTORY = {
  path: '/history',
  text: 'History',
  breadcrumbs: [
    {
      path: '/about',
      text: 'About'
    },
    {
      path: '/history',
      text: 'History'
    }
  ],
  links: []
};

export const HOME = {
  path: '/home',
  text: 'Home',
  breadcrumbs: [],
  links: []
};

export const PROJECT = {
  path: '/projects/:projectNumber',
  text: 'Project',
  breadcrumbs: [
    {
      path: '/projects',
      text: 'Projects'
    }
  ],
  links: []
};

export const PROJECTS = {
  path: '/projects',
  text: 'Projects',
  breadcrumbs: [
    {
      path: '/projects',
      text: 'Projects'
    }
  ],
  links: []
};

export const NOTFOUND = {
  path: null,
  text: 'Not Found',
  breadcrumbs: [],
  links: []
};

const routes = [
  HOME,
  ABOUT,
  CAPABILITIES,
  JOBOPENINGS,
  CONTACT,
  EXPERTISE,
  LEADERSHIP,
  HISTORY,
  PROJECT,
  PROJECTS,
  NOTFOUND
];

export default routes;
