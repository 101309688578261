import PropTypes from 'prop-types';
import styled from 'styled-components';

const Divider = styled.hr`
  border: ${props => (props.borderThickness ? props.borderThickness : 1)}px
    solid ${props => (props.borderColor ? props.borderColor : '#ADA9BB')};
`;

Divider.propTypes = {
  borderColor: PropTypes.string,
  borderThickness: PropTypes.number
};

Divider.displayName = 'Divider';

export { Divider };
