import React from 'react';
import { Box, Container, Flex, Image } from '../elements';
import NavigationLinkDropdown from './NavigationLinkDropdown';
import US from '../assets/US.png';
import { ABOUT, JOBOPENINGS, CONTACT, HOME, PROJECTS } from '../routes';

const NavigationDesktop = () => {
  return (
    <Flex>
      <Box width={1 / 8} p={2}>
        <Image src={US} width="38px" alt="US Flag" />
      </Box>

      <Box width={7 / 8}>
        <Container>
          <nav>
            <Flex alignItems="baseline" p={2}>
              <Box width={1 / 5}>
                <NavigationLinkDropdown link={HOME} />
              </Box>
              <Box width={1 / 5}>
                <NavigationLinkDropdown link={PROJECTS} />
              </Box>
              <Box width={1 / 5}>
                <NavigationLinkDropdown link={ABOUT} />
              </Box>
              <Box width={1 / 5}>
                <NavigationLinkDropdown link={JOBOPENINGS} />
              </Box>
              <Box width={1 / 5}>
                <NavigationLinkDropdown link={CONTACT} />
              </Box>
            </Flex>
          </nav>
        </Container>
      </Box>
    </Flex>
  );
};

export default NavigationDesktop;
