import store from '../../store/initialState';
const initialState = store.jobOpening;

export const FETCH_JOBOPENINGS_REQUEST = 'FETCH_JOBOPENINGS_REQUEST';
export const FETCH_JOBOPENINGS_SUCCESS = 'FETCH_JOBOPENINGS_SUCCESS';
export const FETCH_JOBOPENINGS_FAILURE = 'FETCH_JOBOPENINGS_FAILURE';

export const FETCH_JOBOPENING_REQUEST = 'FETCH_JOBOPENING_REQUEST';
export const FETCH_JOBOPENING_SUCCESS = 'FETCH_JOBOPENING_SUCCESS';
export const FETCH_JOBOPENING_FAILURE = 'FETCH_JOBOPENING_FAILURE';

export const CHANGE_JOBOPENINGS_FILTERS = 'CHANGE_JOBOPENINGS_FILTERS';

export const actions = {
  fetchJobOpeningsRequest: () => ({ type: FETCH_JOBOPENINGS_REQUEST }),
  fetchJobOpeningsSuccess: jobOpenings => ({
    type: FETCH_JOBOPENINGS_SUCCESS,
    jobOpenings
  }),
  fetchJobOpeningsFailure: error => ({
    type: FETCH_JOBOPENINGS_FAILURE,
    error
  }),
  fetchJobOpeningRequest: id => ({ type: FETCH_JOBOPENINGS_REQUEST, id }),
  fetchJobOpeningSuccess: jobOpening => ({
    type: FETCH_JOBOPENING_SUCCESS,
    jobOpening
  }),
  fetchJobOpeningFailure: error => ({
    type: FETCH_JOBOPENING_FAILURE,
    error
  }),
  changeJobOpeningsFilters: filter => ({
    type: CHANGE_JOBOPENINGS_FILTERS,
    filter
  })
};

export const actionCreators = {
  fetchJobOpenings: () => {
    return actions.fetchJobOpeningsRequest();
  },
  fetchJobOpening: id => {
    return actions.fetchJobOpeningRequest(id);
  },
  changeJobOpeningsFilters: filter => {
    return actions.changeJobOpeningsFilters(filter);
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  let filters;
  let filter;

  switch (action.type) {
    case FETCH_JOBOPENINGS_REQUEST:
      return { ...state, error: null, isLoading: true };
    case FETCH_JOBOPENINGS_SUCCESS:
      const jobOpening = action.jobOpenings;
      const jobOpenings = jobOpening.jobOpenings;
      filters = jobOpening.jobOpenings.map(source => {
        const target = {
          isActive: false,
          value: source.id,
          name: source.title,
          count: source.count
        };
        return target;
      });
      return {
        ...state,
        jobOpenings: jobOpenings,
        filters: filters,
        error: null,
        isLoading: false
      };
    case FETCH_JOBOPENINGS_FAILURE:
      return {
        ...state,
        jobOpenings: [],
        filters: [],
        error: action.error,
        isLoading: false
      };
    case FETCH_JOBOPENING_REQUEST:
      return { ...state, error: null, isLoading: true };
    case FETCH_JOBOPENING_SUCCESS:
      return {
        ...state,
        jobOpening: action.jobOpening,
        error: null,
        isLoading: false
      };
    case FETCH_JOBOPENING_FAILURE:
      return {
        ...state,
        jobOpening: null,
        error: action.error,
        isLoading: false
      };

    case CHANGE_JOBOPENINGS_FILTERS:
      filters = state.filters;
      filter = filters.find(item => item.name === action.filter);
      filter.isActive = !filter.isActive;
      return { ...state, filters: [...filters] };

    default:
      return state;
  }
};
