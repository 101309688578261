import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { getPath } from '../../utils/formatter';

import {
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  actions
} from './redux';

import { SET_ACTIVE_PATH } from '../App/redux';

import api from '../../store/api';

function* fetchBanners() {
  try {
    let data = yield call(api.getBanners);
    yield put(actions.fetchBannersSuccess(data));
  } catch (error) {
    yield put(actions.fetchBannersFailure(error));
  }
}

function* fetchBanner(action) {
  try {
    const path = getPath(action.path);
    let data = yield call(api.getBanner, path);
    yield put(actions.fetchBannerSuccess(data));
  } catch (error) {
    yield put(actions.fetchBannerFailure(error));
  }
}

function* setActiveBanner() {
  yield put(actions.setActiveBanner());
}

export default function* bannersData() {
  yield takeLatest(FETCH_BANNERS_REQUEST, fetchBanners);
  yield takeLatest(FETCH_BANNER_REQUEST, fetchBanner);
  yield takeEvery(
    [SET_ACTIVE_PATH, FETCH_BANNERS_SUCCESS, FETCH_BANNER_SUCCESS],
    setActiveBanner
  );
}
