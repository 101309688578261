import React from 'react';
import { Box } from 'rebass';

const Container = props => (
  <Box
    {...props}
    mx="auto"
    style={{
      maxWidth: '1280px',
      flex: '1 1 auto',
      width: '100%',
      overflow: 'hidden'
    }}
  />
);

export { Container };
