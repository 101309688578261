import Loadable from 'react-loadable';

import ComponentLoader from '../../components/ComponentLoader';

export default Loadable({
  loading: ComponentLoader,
  loader: () =>
    import(/* webpackChunkName: "expertise" */
    './ExpertisePage')
});
