import 'airbnb-browser-shims';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createHistory } from '@reach/router';
import configureStore from './store/configureStore';
import App from './containers/App';
import './theme/globalStyles';
import initialState from './store/initialState';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

let history = createHistory(window);
//const initialState = window.initialReduxState;
const store = configureStore(initialState);

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  rootElement
);
