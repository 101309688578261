import React from 'react';
import { Box, Flex, Container } from '../elements';
import AISC from './AISC';
import CWF from './CWF';
import SAFE from './Safe';

const PageFooter = () => {
  return (
    <Flex flexDirection="column">
      <Container>
        <Flex alignItems="center">
          <Box my={1} mx="auto">
            <AISC />
          </Box>
          <Box mx="auto">
            <SAFE />
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default PageFooter;
