import PropTypes from 'prop-types';
import styled from 'styled-components';

const Panel = styled.div`
  border: ${props => (props.borderThickness ? props.borderThickness : 1)}px
    solid ${props => (props.borderColor ? props.borderColor : '#ADA9BB')};
`;

Panel.propTypes = {
  borderColor: PropTypes.string,
  borderThickness: PropTypes.number
};

Panel.displayName = 'Panel';

export { Panel };
