const initialState = {
  banner: {
    isLoading: false,
    error: null,
    activePath: null,
    activeBanner: {
      height: 25,
      breadcrumbs: [],
      slideShowPhotos: [],
      photo: null,
      video: null,
      showSlideshow: false,
      showPhoto: false,
      showVideo: false,
      showMessage: false,
      messageText: null
    },
    banners: []
  },
  jobOpening: {
    isLoading: false,
    error: null,
    filters: [],
    jobOpenings: []
  },
  project: {
    isLoading: false,
    error: null,
    filters: [],
    projects: []
  },
  app: {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    profile: null,
    activePath: null,
    activePage: null,
    pages: []
  }
};

export default initialState;
