const colors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  BLUE: '#001C6D',
  BLUE1: '#004695',
  BLUE2: '#006DAD',
  GRAY: '#ADA9BB',
  DKGRAY: '#484554',
  DARKEN: 'rgba(0, 0, 0, 0.4)'
};

export default colors;
