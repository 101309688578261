import { takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_PROJECTS_REQUEST, actions } from './redux';

import api from '../../store/api';

function* fetchProjects(action) {
  try {
    let data = yield call(api.getProjects);
    yield put(actions.fetchProjectsSuccess(data));
  } catch (error) {
    yield put(actions.fetchProjectsFailure(error));
  }
}

export default function* projectsData() {
  yield takeLatest(FETCH_PROJECTS_REQUEST, fetchProjects);
}
