import { takeLatest, call, put } from 'redux-saga/effects';

import { FETCH_JOBOPENINGS_REQUEST, actions } from './redux';

import api from '../../store/api';

function* fetchJobOpenings(action) {
  try {
    let data = yield call(api.getJobOpenings);
    yield put(actions.fetchJobOpeningsSuccess(data));
  } catch (error) {
    yield put(actions.fetchJobOpeningsFailure(error));
  }
}

export default function* jobOpeningsData() {
  yield takeLatest(FETCH_JOBOPENINGS_REQUEST, fetchJobOpenings);
}
