import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html, body {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}

#root {
  max-width: 100vw;
  min-height: 100vh;
}

* { box-sizing: border-box; }
`;

export default GlobalStyles;
