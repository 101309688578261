import { Helmet } from 'react-helmet';
import { Box, Container } from '../elements';
import Error from './Error';
import FullscreenLoader from './FullscreenLoader';
import PropTypes from 'prop-types';
import React from 'react';

const Page = ({ children, description, error, isLoading, title }) => {
  const siteUrl = 'http://www.gaylemfg.com';

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <title>{`${title} | Gayle Manufacturing Company`}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content="Gayle Manufacturing Company" />
        <meta
          property="og:title"
          content={`${title} | Gayle Manufacturing Company`}
        />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${siteUrl}/images/logo.png`} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
      </Helmet>
      <Box flex={1}>
        {error ? (
          <Error error={error} />
        ) : isLoading ? (
          <FullscreenLoader />
        ) : (
          <Container p={3}>{children}</Container>
        )}
      </Box>
    </React.Fragment>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

export default Page;
