import PropTypes from 'prop-types';
import React from 'react';
import { Caps, Flex } from '../elements';

const NavigationLinkText = ({ color, fontSize, text }) => {
  return (
    <Flex width={1} justify="center" color={color}>
      <Caps fontSize={fontSize} fontWeight="bold" textAlign="center">
        {text}
      </Caps>
    </Flex>
  );
};

NavigationLinkText.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.array,
  text: PropTypes.string.isRequired
};

NavigationLinkText.defaultProps = {
  fontSize: [3, null, 4]
};

export default NavigationLinkText;
