import React from 'react';
import { Box, Image } from '../elements';
import aisc from '../assets/aisc.png';

const CWF = () => {
  return (
    <Box bg="WHITE">
      <Image
        src={aisc}
        width={143}
        height={60}
        alt="AISC Certified Fabricator"
      />
    </Box>
  );
};

export default CWF;
