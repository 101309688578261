import { Box, Drawer, Flex, Image } from '../elements';
import Close from '../icons/Close';
import Menu from '../icons/Menu';
import React, { Component } from 'react';
import usflag from '../assets/US.png';
import {
  ABOUT,
  CAPABILITIES,
  JOBOPENINGS,
  CONTACT,
  EXPERTISE,
  HISTORY,
  HOME,
  LEADERSHIP
} from '../routes';
import NavigationLink from './NavigationLink';
class NavigationMobile extends Component {
  state = {
    isOpen: false
  };

  handleToggleNavigation = event => {
    event.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <Box>
        <Flex alignItems="top">
          <Box width={1 / 3} p={2}>
            <Image mt="auto" src={usflag} />
          </Box>
          <Box mx="auto" />
          {isOpen ? (
            <Flex flexDirection="column">
              <Flex>
                <Box mx="auto" />
                <Close
                  size={30}
                  color="WHITE"
                  p={1}
                  m={1}
                  onClick={this.handleToggleNavigation}
                />
              </Flex>

              <Drawer open={isOpen} position="left" color="WHITE" bg="BLUE">
                <nav>
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="top"
                  >
                    <Box onClick={this.handleToggleNavigation}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={HOME.path}
                        text={HOME.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to="/projects"
                        text="Projects"
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={ABOUT.path}
                        text={ABOUT.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation} ml={4}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={EXPERTISE.path}
                        text={EXPERTISE.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation} ml={4}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={CAPABILITIES.path}
                        text={CAPABILITIES.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation} ml={4}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={LEADERSHIP.path}
                        text={LEADERSHIP.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation} ml={4}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={HISTORY.path}
                        text={HISTORY.text}
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to="/jobopenings"
                        text="Careers"
                      />
                    </Box>
                    <Box onClick={this.handleToggleNavigation}>
                      <NavigationLink
                        justify="left"
                        fontSize={[3, null, 3]}
                        to={CONTACT.path}
                        text={CONTACT.text}
                      />
                    </Box>
                  </Flex>
                </nav>
              </Drawer>
            </Flex>
          ) : (
            <Menu
              size={30}
              color="WHITE"
              p={1}
              m={1}
              onClick={this.handleToggleNavigation}
            />
          )}
        </Flex>
      </Box>
    );
  }
}

export default NavigationMobile;
