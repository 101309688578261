import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'react-loading';
import { Flex, Box } from '../elements';

const FullscreenLoader = ({ delay, size }) => (
  <Flex style={{ height: '100%' }} alignItems="center">
    <Box mx="auto">
      <Loading
        delay={delay}
        type="bubbles"
        color="#ADA9BB"
        height={size}
        width={size}
      />
    </Box>
  </Flex>
);

FullscreenLoader.propTypes = {
  delay: PropTypes.number,
  size: PropTypes.number
};

FullscreenLoader.defaultProps = {
  delay: 300,
  size: 150
};

export default FullscreenLoader;
