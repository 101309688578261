import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '@reach/router';
import colors from '../theme/colors';
import { Flex } from '../elements';
import NavigationLinkText from './NavigationLinkText';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: colors.WHITE,
          textDecoration: isCurrent ? 'underline' : 'none'
        }
      };
    }}
  />
);

const NavigationLink = ({ color, children, fontSize, justify, text, to }) => {
  return (
    <Flex justify={justify} m={2}>
      <NavLink to={to}>
        {children ? (
          children
        ) : (
          <NavigationLinkText fontSize={fontSize} text={text} color={color} />
        )}
      </NavLink>
    </Flex>
  );
};

NavigationLink.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.array,
  justify: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string
};

NavigationLink.defaultProps = {
  fontSize: [1, null, 3],
  justify: 'center',
  color: 'WHITE'
};

export default NavigationLink;
