import { createSelector } from 'reselect';

const getDomain = state => state.app;

export const selectPages = state => getDomain(state).pages;
export const selectActivePath = state => getDomain(state).activePath;
export const selectActivePage = state => getDomain(state).activePage;
export const selectIsLoading = state => getDomain(state).isLoading;
export const selectError = state => getDomain(state).error;
export const selectIsAuthenticated = state => getDomain(state).isAuthenticated;
export const selectToken = state => getDomain(state).idToken;
export const selectProfile = state => getDomain(state).profile;

export const selectContentSections = createSelector(
  [selectActivePage],
  page => {
    const contentSections = page ? page.contentSections : [];

    if (!contentSections) {
      return [];
    }

    return contentSections.sort((item1, item2) => {
      return item1.sort < item2.sort ? -1 : 1;
    });
  }
);

export const selectPageTitle = createSelector([selectActivePage], page => {
  const title = page ? page.title : '';
  return title;
});

export const selectPageDescription = createSelector(
  [selectActivePage],
  page => {
    const description = page ? page.description : '';

    return description;
  }
);
