import React from 'react';

const Stop = () => {
  return (
    <svg viewBox="0 0 800 800">
      <g
        id="unauthorized-page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="boundary">
          <g id="unauthorized">
            <path
              d="M206,647 C206,647 262.396104,595.041237 292.474026,575.247423 C307.512987,565.350515 301.246753,566.587629 320.045455,567.824742 C346.363636,569.061856 399,580.195876 399,580.195876"
              id="Shape"
              stroke="#001C6D"
              strokeWidth="6"
            />
            <path
              d="M99,490.5 L143,490.5"
              id="Path"
              stroke="#001C6D"
              strokeWidth="6"
            />
            <path
              d="M358,488 L269,488"
              id="Shape"
              stroke="#001C6D"
              strokeWidth="6"
            />
            <path
              d="M154,328 L259,328 C269.5,328 280,338.842751 280,349.685501 L280,465.369378 C273.340149,508.702067 248.840149,529.902465 206.5,528.970572 C164.159851,528.038678 139.659851,506.83828 133,465.369378 L133,349.685501 C133,338.842751 143.5,328 154,328 Z M154,338.842751 L143.5,349.685501 L143.5,458.113008 C143.5,463.534383 147.170901,519.906662 206.5,519.906662 C265.829099,519.906662 269.5,463.534383 269.5,458.113008 L269.5,349.685501 L259,338.842751 L154,338.842751 Z M159.25,376.792378 L253.75,376.792378 L253.75,431.006131 L159.25,431.006131 L159.25,376.792378 Z M169.75,387.635129 L169.75,420.163381 L243.25,420.163381 L243.25,387.635129 L169.75,387.635129 Z"
              id="Helmet"
              fill="#001C6D"
              fillRule="nonzero"
            />
            <path
              d="M399.581393,0.00536827926 C302.769807,-0.499426734 209.151677,34.6120857 136.543316,98.6578908 L135.113761,101.517384 L130.825097,104.376878 L129.395542,105.806624 C123.50652,111.352195 117.785711,117.073772 112.240885,122.963585 L106.522666,128.682572 L105.093111,130.112318 L100.804447,135.831305 L95.0862278,141.550292 C-39.7251415,299.581445 -30.1236076,534.784688 117.119988,681.298621 C264.363584,827.812554 499.581178,836.215243 656.901252,700.581253 L658.330806,699.151506 L659.760361,697.721759 C662.161333,695.838524 664.544036,693.932106 666.908135,692.002772 L666.908135,690.573026 L671.196799,687.713532 L675.485464,683.424292 C679.342128,679.65642 683.154535,675.843501 686.921902,671.986319 L694.069676,664.837585 L698.35834,659.118598 L699.787895,657.688852 C762.7685,585.66946 797.33042,493.153078 796.997619,397.474952 C796.997619,177.293959 618.303273,0.00536827926 399.581393,0.00536827926 Z M399.581393,88.6496638 C518.400635,88.4376697 626.801264,156.430348 678.346989,263.501227 C729.892714,370.572106 715.438482,497.725066 641.176149,590.490756 L205.161945,157.277506 C258.055471,114.385104 326.6741,88.6496638 399.581393,88.6496638 Z M157.986638,204.459147 L594.000842,637.672398 C471.568271,738.856387 292.357846,730.64939 179.683229,618.698593 C67.008611,506.747797 57.6238898,327.571183 157.986638,204.459147 Z"
              id="Stop"
              fill="#CC0000"
              fillRule="nonzero"
            />
            <path
              d="M397.415581,434.974991 L414.569915,208.186258 C417.42897,170.866593 463.173861,178.043452 461.744333,209.62163 L456.026222,330.192855 C453.167166,347.417315 467.462445,347.417315 468.891973,333.063598 L484.616779,173.737337 C494.623474,140.723787 536.079781,153.642133 534.650253,179.478824 L517.495919,345.981944 C513.207336,364.641776 528.932142,363.206404 531.791198,353.158802 L548.945532,208.186258 C557.522699,178.043452 601.838062,190.961797 597.549478,213.927745 L577.536088,374.689378 C571.817977,389.043095 584.683728,389.043095 588.972311,378.995493 L621.851451,292.87319 C633.287674,274.213357 666.166814,287.131703 657.589647,312.968394 L610.415229,432.104247 C596.11995,465.117797 591.831367,505.308205 584.683728,528.274153 C577.536088,548.369357 540.368365,594.301252 511.777808,605.784226 C481.757723,618.702571 418.858498,605.784226 397.415581,587.124393 C363.106913,559.852331 371.68408,548.369357 364.53644,536.886383 C351.67069,519.661922 331.6573,478.036142 325.939189,463.682425 L288.771465,413.444415 C277.335242,393.349211 294.489576,381.866237 305.925799,380.430865 C327.368717,378.995493 338.804939,387.607724 348.811634,401.961441 L380.261247,445.022593 C387.408886,459.37631 397.415581,456.505566 397.415581,434.974991 Z"
              id="Hand"
              fill="#001C6D"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Stop;
