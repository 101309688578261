import store from '../../store/initialState';
import { ZOOM_IN, ZOOM_OUT } from '../../utils/animations/slideShowEffects';
const initialState = store.banner;
export const FETCH_BANNERS_REQUEST = 'FETCH_BANNERS_REQUEST';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAILURE = 'FETCH_BANNERS_FAILURE';

export const FETCH_BANNER_REQUEST = 'FETCH_BANNER_REQUEST';
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_FAILURE = 'FETCH_BANNER_FAILURE';

export const SET_ACTIVE_BANNER = 'SET_ACTIVE_BANNER';
export const SET_ACTIVE_PATH = 'SET_ACTIVE_PATH';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const actions = {
  fetchBannersRequest: () => ({ type: FETCH_BANNERS_REQUEST }),
  fetchBannersSuccess: banners => ({
    type: FETCH_BANNERS_SUCCESS,
    banners
  }),
  fetchBannersFailure: error => ({
    type: FETCH_BANNERS_FAILURE,
    error
  }),

  fetchBannerRequest: path => ({ type: FETCH_BANNER_REQUEST, path }),
  fetchBannerSuccess: banner => ({
    type: FETCH_BANNER_SUCCESS,
    banner
  }),
  fetchBannerFailure: error => ({
    type: FETCH_BANNER_FAILURE,
    error
  }),

  setActiveBanner: () => ({ type: SET_ACTIVE_BANNER }),
  setBreadcrumbs: breadcrumbs => ({ type: SET_BREADCRUMBS, breadcrumbs }),
  setActivePath: path => ({ type: SET_ACTIVE_PATH, path })
};

export const actionCreators = {
  fetchBanners: () => {
    return actions.fetchBannersRequest();
  },
  fetchBanner: path => {
    return actions.fetchBannerRequest(path);
  },
  setActiveBanner: () => {
    return actions.setActiveBanner();
  },
  setBreadcrumbs: breadcrumbs => {
    return actions.setBreadcrumbs(breadcrumbs);
  },
  setActivePath: path => {
    return actions.setActivePath(path);
  }
};

const getPosition = position => {
  switch (position) {
    case 0:
      return 0.5;
    case 1:
      return 1.0;
    default:
      return 0.0;
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  let banners;
  let activeBanner;
  let timelineItems;
  let from;
  let to;
  let targetStart;
  let targetEnd;
  let zoom;
  let height;

  switch (action.type) {
    case FETCH_BANNERS_REQUEST:
      return { ...state, error: null, isLoading: true };

    case FETCH_BANNERS_SUCCESS:
      const payload = action.banners;
      banners = payload.banners.map(source => {
        height = source.height >= 25 ? source.height : 25;

        if (source.showSlideshow && source.slideshowPhotos !== null) {
          timelineItems = source.slideshowPhotos.map(photo => {
            targetStart = photo.targetStart;
            targetEnd = photo.targetEnd;
            zoom = (100 - photo.zoom) / 100;

            if (targetStart && targetEnd) {
              if (photo.effect === ZOOM_IN) {
                from = [
                  1,
                  [
                    getPosition(photo.targetStart.x),
                    getPosition(photo.targetStart.y)
                  ]
                ];

                to = [
                  zoom,
                  [
                    getPosition(photo.targetEnd.x),
                    getPosition(photo.targetEnd.y)
                  ]
                ];
              } else if (photo.effect === ZOOM_OUT) {
                from = [
                  zoom,
                  [
                    getPosition(photo.targetStart.x),
                    getPosition(photo.targetStart.y)
                  ]
                ];
                to = [
                  1,
                  [
                    getPosition(photo.targetEnd.x),
                    getPosition(photo.targetEnd.y)
                  ]
                ];
              } else {
                from = [
                  zoom,
                  [
                    getPosition(photo.targetStart.x),
                    getPosition(photo.targetStart.y)
                  ]
                ];
                to = [
                  zoom,
                  [
                    getPosition(photo.targetEnd.x),
                    getPosition(photo.targetEnd.y)
                  ]
                ];
              }
            }

            const timelineItem = {
              image: photo.src,
              duration: 12000,
              kenburns: {
                from: from,
                to: to
              },
              transitionNext: { name: 'DirectionalWipe', duration: 2000 }
            };
            return timelineItem;
          });
        } else if (source.showVideo && source.video !== null) {
          timelineItems = [];

          const video = source.video;

          const timelineItem = {
            video: video.src,
            loop: true,
            duration: 4000
          };

          timelineItems.push(timelineItem);
        } else {
          timelineItems = null;
        }

        const target = {
          credit: source.credit,
          height: height,
          isDefault: source.isDefault,
          messageText: source.messageText,
          path: source.path,
          photo: source.photo,
          showMessage: source.showMessage,
          showPhoto: source.showPhoto,
          showSlideshow: source.showSlideshow,
          showVideo: source.showVideo,
          slideShowCount: source.slideShowCount,
          slideShowPhotos: timelineItems,
          sort: source.sort,
          video: source.video
        };

        return target;
      });
      return {
        ...state,
        banners: banners,
        error: null,
        isLoading: false
      };

    case FETCH_BANNERS_FAILURE:
      return { ...state, banners: [], error: action.error, isLoading: false };

    case FETCH_BANNER_REQUEST:
      return { ...state, path: action.path, error: null, isLoading: true };
    case FETCH_BANNER_SUCCESS:
      banners = state.banners;
      const activeBanners = action.banner;
      activeBanner = activeBanners.banners[0];

      return {
        ...state,
        activeBanner: activeBanner,
        banners: [...banners, activeBanner],
        error: null,
        isLoading: false
      };
    case FETCH_BANNER_FAILURE:
      return { ...state, banner: null, error: action.error, isLoading: false };

    case SET_ACTIVE_BANNER:
      const path = state.activePath;
      if (!path) {
        return state;
      }
      const selectedBanner = state.banners.find(banner => banner.path === path);
      activeBanner = selectedBanner
        ? { ...selectedBanner }
        : {
            height: 25,
            path: null,
            breadcrumbs: [],
            slideShowPhotos: [],
            photo: null,
            video: null,
            showSlideshow: false,
            showPhoto: false,
            showVideo: false,
            showMessage: false,
            messageText: null
          };

      return { ...state, activeBanner: activeBanner };

    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: action.breadcrumbs };

    case SET_ACTIVE_PATH:
      const activePath = action.path;
      return { ...state, activePath: activePath };

    default:
      return state;
  }
};
