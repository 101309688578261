import store from '../../store/initialState';
const initialState = store.app;

export const FETCH_PAGES_REQUEST = 'FETCH_PAGES_REQUEST';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAILURE = 'FETCH_PAGES_FAILURE';
export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE';
export const SET_ACTIVE_PATH = 'SET_ACTIVE_PATH';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

export const actions = {
  fetchPagesRequest: () => ({ type: FETCH_PAGES_REQUEST }),

  fetchPagesSuccess: pages => ({
    type: FETCH_PAGES_SUCCESS,
    pages
  }),

  fetchPagesFailure: error => ({
    type: FETCH_PAGES_FAILURE,
    error
  }),

  fetchPageRequest: path => ({ type: FETCH_PAGE_REQUEST, path }),

  fetchPageSuccess: page => ({
    type: FETCH_PAGE_SUCCESS,
    page
  }),

  fetchPageFailure: error => ({
    type: FETCH_PAGE_FAILURE,
    error
  }),

  setActivePath: path => ({ type: SET_ACTIVE_PATH, path }),

  setActivePage: () => ({ type: SET_ACTIVE_PAGE })
};

export const actionCreators = {
  fetchPages: () => {
    return actions.fetchPagesRequest();
  },

  fetchPage: path => {
    return actions.fetchPageRequest(path);
  },

  setActivePath: path => {
    return actions.setActivePath(path);
  },

  setActivePage: () => {
    return actions.setActivePage();
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  let pages;
  let activePage;

  switch (action.type) {
    case FETCH_PAGES_REQUEST:
      return { ...state, error: null };

    case FETCH_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.pages.pages,
        error: null
      };

    case FETCH_PAGES_FAILURE:
      return {
        ...state,
        pages: [],
        error: action.error
      };

    case FETCH_PAGE_REQUEST:
      return { ...state, path: action.path, error: null, isLoading: true };

    case FETCH_PAGE_SUCCESS:
      pages = state.pages;
      const activePages = action.page;
      activePage = activePages.pages[0];
      return {
        ...state,
        activePage: activePage,
        pages: [...pages, activePage],
        error: null,
        isLoading: false
      };

    case FETCH_PAGE_FAILURE:
      return {
        ...state,
        activePage: null,
        error: action.error,
        isLoading: false
      };

    case SET_ACTIVE_PAGE:
      const path = state.activePath;
      if (!path) {
        return state;
      }
      activePage = state.pages.find(page => page.path === path);
      return { ...state, activePage: activePage };

    case SET_ACTIVE_PATH:
      return { ...state, activePath: action.path };

    default:
      return state;
  }
};
