const dev = {
  gmcApi: {
    URL: 'https://localhost:44300/api'
  },
  auth0: {
    CALLBACK_URL: 'http://localhost:3000/callback',
    AUDIENCE: 'https://gaylemfg.com/api',
    DOMAIN: 'gmcx.auth0.com',
    CLIENT_ID: 'WemC5zcgn8qSXdbNetYaHbmvHOtVS17f'
  }
};

const test = {
  gmcApi: {
    URL: 'http://testapi.gaylemfg.com/api'
  },
  auth0: {
    CALLBACK_URL: 'http://test-gaylemfg-com/callback',
    AUDIENCE: 'https://gaylemfg.com/api',
    DOMAIN: 'gmcx.auth0.com',
    CLIENT_ID: 'WemC5zcgn8qSXdbNetYaHbmvHOtVS17f'
  }
};

const prod = {
  gmcApi: {
    URL: 'https://api.gaylemfg.com/api'
  },
  auth0: {
    CALLBACK_URL: 'https://www.gaylemfg.com/callback',
    AUDIENCE: 'https://gaylemfg.com/api',
    DOMAIN: 'gmcx.auth0.com',
    CLIENT_ID: 'WemC5zcgn8qSXdbNetYaHbmvHOtVS17f'
  }
};

const deploy = process.env.REACT_APP_DEPLOY;
let config;

switch (deploy) {
  case 'test':
    config = test;
    break;
  case 'production':
    config = prod;
    break;
  default:
    config = dev;
}

export default {
  // Add common config values here
  ...config
};
