import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Box } from '../../elements';
import { selectError, selectIsLoading } from './selectors';
import theme from '../../theme/theme';
import FooterComponent from '../../components/Footer';
import BannerContainer from '../BannerContainer';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import MainRoutes from './MainRoutes';
import PageFooter from '../../components/PageFooter';
import GlobalStyles from '../../theme/globalStyles';
import CacheBuster from '../../CacheBuster';

ReactGA.initialize('UA-138437055-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Site = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  flex-basis: auto;
  min-height: 100%;
  height: 100%;
  margin-top: auto;
`;

const Header = styled.header`
  min-height: 1.5em;
`;

const Footer = styled.footer`
  min-height: 1.5em;
  flex-shrink: 0;
`;

class App extends Component {
  render() {
  const { history } = this.props;
  
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <ThemeProvider theme={theme}>
              <>
                <Site>
                  <ScrollToTop>
                    <Header>
                      <Box bg="BLUE" color="WHITE">
                        <Navigation />
                      </Box>
                    </Header>
                    <Content>
                      <BannerContainer />
                      <MainRoutes history={history} />
                    </Content>
                    <Footer>
                      <Box m={0} p={1} bg="WHITE">
                        <PageFooter />
                      </Box>
                      <Box m={0} p={1} bg="BLUE" color="WHITE">
                        <FooterComponent />
                      </Box>
                    </Footer>
                  </ScrollToTop>
                </Site>
                <GlobalStyles />
              </>
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    );
  }
}



App.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => ({
  error: selectError(state),
  isLoading: selectIsLoading(state)
});

export default connect(mapStateToProps)(App);
