import React from 'react';
import Page from './Page';
import Stop from './Stop';
import { Box, Caps, Flex } from '../elements';

const NotFound = () => {
  return (
    <Page title="Not Found">
      <Flex flexDirection="column" justify="center" width={1}>
        <Box color="BLUE" width={1} m={3}>
          <Caps fontSize={[3, null, 6]} fontWeight="bold" textAlign="center">
            Page Not Found
          </Caps>
        </Box>
        <Box width={1 / 2} mx="auto">
          <Stop />
        </Box>
      </Flex>
    </Page>
  );
};

export default NotFound;
