import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as BannerStore from '../containers/BannerContainer/redux';
import * as JobOpeningStore from '../containers/JobOpeningsPage/redux';
import * as ProjectStore from '../containers/ProjectsPage/redux';
import * as AppStore from '../containers/App/redux';

import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
  const reducers = {
    banner: BannerStore.reducer,
    jobOpening: JobOpeningStore.reducer,
    project: ProjectStore.reducer,
    app: AppStore.reducer
  };

  const middleware = [sagaMiddleware];
  const enhancers = [];
  const rootReducer = combineReducers({
    ...reducers
  });

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
