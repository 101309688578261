import colors from './colors';

const theme = {
  // breakpoints: [32, 48, 64, 80],
  // space: [0, 4, 8, 16, 32, 64, 128],
  // fonts: {
  //   sans: 'Tahoma, Verdana, Segoe, system-ui, sans-serif',
  //   mono: 'Menlo, monospace'
  // },
  // fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  // weights: {
  //   normal: 400,
  //   bold: 700
  // },
  colors
  // radii: [0, 2, 4]
};

export default theme;
