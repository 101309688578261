import { fork } from 'redux-saga/effects';

import projectSagas from '../containers/ProjectsPage/sagas';
import jobOpeningSagas from '../containers/JobOpeningsPage/sagas';
import bannerSagas from '../containers/BannerContainer/sagas';
import appSagas from '../containers/App/sagas';

export default function* rootSaga() {
  yield [
    fork(projectSagas),
    fork(jobOpeningSagas),
    fork(bannerSagas),
    fork(appSagas)
  ];
}
