import React from 'react';
import { Router, Redirect } from '@reach/router';
import AboutPage from '../AboutPage';
import JobOpeningsPage from '../JobOpeningsPage';
import CapabilitiesPage from '../CapabilitiesPage';
import HomePage from '../HomePage';
import ProjectPage from '../ProjectPage';
import ProjectsPage from '../ProjectsPage';
import ContactPage from '../ContactPage';
import LeadershipPage from '../LeadershipPage';
import HistoryPage from '../HistoryPage';
import ExpertisePage from '../ExpertisePage';
import NotFound from '../../components/NotFound';

import {
  HOME,
  ABOUT,
  CAPABILITIES,
  JOBOPENINGS,
  CONTACT,
  EXPERTISE,
  LEADERSHIP,
  HISTORY,
  PROJECT,
  PROJECTS
} from '../../routes';

const MainRoutes = ({ history }) => {
  return (
    <Router history={history}>
      <Redirect from="/" to={HOME.path} />
      <HomePage path={HOME.path} />
      <AboutPage path={ABOUT.path} />
      <ProjectPage path={PROJECT.path} />
      <ProjectsPage path={PROJECTS.path} />
      <JobOpeningsPage path={JOBOPENINGS.path} />
      <ExpertisePage path={EXPERTISE.path} />
      <CapabilitiesPage path={CAPABILITIES.path} />
      <LeadershipPage path={LEADERSHIP.path} />
      <HistoryPage path={HISTORY.path} />
      <ContactPage path={CONTACT.path} />
      <NotFound default />
    </Router>
  );
};

export default MainRoutes;
