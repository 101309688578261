import PropTypes from 'prop-types';
import React from 'react';

import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import Mobile from './Mobile';
import Tablet from './Tablet';
import Desktop from './Desktop';

const Navigation = () => {
  return (
    <React.Fragment>
      <Mobile>
        <NavigationMobile />
      </Mobile>

      <Tablet>
        <NavigationDesktop />
      </Tablet>

      <Desktop>
        <NavigationDesktop />
      </Desktop>
    </React.Fragment>
  );
};

Navigation.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default Navigation;
