import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { getPath } from '../../utils/formatter';

import {
  FETCH_PAGES_REQUEST,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  SET_ACTIVE_PATH,
  actions
} from './redux';

import api from '../../store/api';

function* fetchPages() {
  try {
    let data = yield call(api.getPages);
    yield put(actions.fetchPagesSuccess(data));
  } catch (error) {
    yield put(actions.fetchPagesFailure(error));
  }
}

function* fetchPage(action) {
  try {
    const path = getPath(action.path);
    let data = yield call(api.getPage, path);
    yield put(actions.fetchPageSuccess(data));
  } catch (error) {
    yield put(actions.fetchPageFailure(error));
  }
}

function* setActivePage() {
  yield put(actions.setActivePage());
}

export default function* appData() {
  yield takeLatest(FETCH_PAGES_REQUEST, fetchPages);
  yield takeLatest(FETCH_PAGE_REQUEST, fetchPage);
  yield takeEvery(
    [SET_ACTIVE_PATH, FETCH_PAGES_SUCCESS, FETCH_PAGE_SUCCESS],
    setActivePage
  );
}
