import React from 'react';
import { Flex, Box, Caps } from '../elements';

const Footer = () => {
  return (
    <Flex color="WHITE" alignItems="baseline">
      <Box mx="auto" p={2}>
        <Caps
          fontWeight="bold"
          fontSize={[2, null, null, 3]}
          textAlign="center"
          children={`© 2024 Gayle Manufacturing Company`}
        />
      </Box>
    </Flex>
  );
};

export default Footer;
