import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { Box } from '../elements';

const Mobile = ({ actualWidth, children }) => {
  return (
    <React.Fragment>
      {actualWidth < 768 && <Box children={children} />}
    </React.Fragment>
  );
};

Mobile.propTypes = {
  actualHeight: PropTypes.number.isRequired,
  actualWidth: PropTypes.number.isRequired,
  children: PropTypes.node
};

const mapSizesToProps = ({ height, width }) => ({
  actualHeight: height,
  actualWidth: width
});

export default withSizes(mapSizesToProps)(Mobile);
