export const ZOOM_OUT = 'ZOOM_OUT';
export const ZOOM_IN = 'ZOOM_IN';
export const PAN = 'PAN';

export const LEFT_TOP = 'LEFT_TOP';
export const MIDDLE_TOP = 'MIDDLE_TOP';
export const RIGHT_TOP = 'RIGHT_TOP';

export const LEFT_CENTER = 'LEFT_CENTER';
export const MIDDLE_CENTER = 'MIDDLE_CENTER';
export const RIGHT_CENTER = 'RIGHT_CENTER';

export const LEFT_BOTTOM = 'LEFT_BOTTOM';
export const MIDDLE_BOTTOM = 'MIDDLE_BOTTOM';
export const RIGHT_BOTTOM = 'RIGHT_BOTTOM';
