export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const replaceNewLine = markup => {
  return { __html: markup.replace(/\r\n/g, '<br/>') };
};

export const getPath = path => {
  return path.toString().replace('/', '');
};
