import { UnauthorizedError } from '../utils/errors';
import config from '../config';

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    const unauthorizedError = new UnauthorizedError(response.statusText);
    unauthorizedError.response = response;
    throw unauthorizedError;
  } else {
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
  }
};

const parseJSON = response => {
  return response.json();
};

class api {
  static getProjects(success) {
    return fetch(`${config.gmcApi.URL}/portfolio`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getJobOpenings(success) {
    return fetch(`${config.gmcApi.URL}/jobopening`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getBanners(success) {
    return fetch(`${config.gmcApi.URL}/banner`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getPages(success) {
    return fetch(`${config.gmcApi.URL}/page`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getProject(number, success) {
    return fetch(`${config.gmcApi.URL}/portfolio/${number}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getJobOpening(id, success) {
    return fetch(`${config.gmcApi.URL}/jobopening/${id}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getBanner(path, success) {
    return fetch(`${config.gmcApi.URL}/banner/${path}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }

  static getPage(path, success) {
    return fetch(`${config.gmcApi.URL}/page/${path}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(success);
  }
}

export default api;
